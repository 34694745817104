<template>
  <eagle-form :form-key="formKey"></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './providerConfigForm'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'provider-config-form',
    meta: {},
  }),
  methods: {
    async updateProviderDefaultRichMenu(photo) {
      try {
        await this.$api.collection.lineApi.updateProviderDefaultRichMenu({ photo })
        this.$snotify.success(null, 'Rich Menu更新成功')
      } catch (error) {
        console.warn(error.headers.reason)
        this.$snotify.error('請查看主控台錯誤訊息', 'Rich Menu更新失敗')
      }
    },
    async updateProviderRichMenu(photo) {
      try {
        await this.$api.collection.lineApi.updateProviderRichMenu({ photo })
        this.$snotify.success(null, 'Rich Menu更新成功')
      } catch (error) {
        console.warn(error.headers.reason)
        this.$snotify.error('請查看主控台錯誤訊息', 'Rich Menu更新失敗')
      }
    },
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    async readApi() {
      return await this.$api.collection.baseApi.appConfigRead('provider')
    },
    async updateApi(target, formData) {
      return await this.$api.collection.baseApi.appConfigUpdate('provider', formData)
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
