import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {

    }
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => this.vm?.$t('page.app-config.provider'),
      hasWritePermission: (hasRole): boolean => hasRole(['ROLE_ADMIN']),
      defaultData: this._defaultData,
      blocks: [
        {
          data: {
            rich_menu_unauth: {
              label: '驗證前Rich Menu',
              type: 'photo',
              disabledRemove: true,
              // @ts-ignore
              onPhotoUpdate: (photo: AnyObject) => this.vm.updateProviderDefaultRichMenu(photo),
            },
            rich_menu_auth: {
              label: '驗證後Rich Menu',
              type: 'photo',
              disabledRemove: true,
              // @ts-ignore
              onPhotoUpdate: (photo: AnyObject) => this.vm.updateProviderRichMenu(photo),
            },
          },
        },
      ],
      dataAction: {
        delete: false,
      },
    }
  }
}

export default new formConfig()
